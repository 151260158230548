import React from 'react'
import "./../Faqsection/Faqsection.css";
function Faqsection() {
  return (
    <div className='main_div3 pt-2'>
      <div className='faq_color'>
        <p className='faq_input mt-4'>
        FAQ
        </p>
      </div>
    </div>
  )
}

export default Faqsection
