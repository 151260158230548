
import './App.css';

import Mergallcamponents from "./Camponents/Mergallcamponents/Mergallcamponents";
function App() {
  return (
    <>
    
     <Mergallcamponents/>
    </>
  );
}

export default App;
